<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col
        cols="12"
        v-for="(item, index) in items"
        :key="index"
        @click="lerItem(item)"
      >
        <v-card color="primary" class="elevation-0">
          <v-card-text
            :class="
              (item.lido ? '' : 'text-truncate') + ' px-3 py-5 white--text'
            "
          >
            {{ item.descricao }}
            <v-chip
              :disabled="item.lido"
              class="mt-1"
              small
              right
            >
              <v-icon
                  color="primary"
                  v-if="item.lido && item.link"
                  size="12"
              >fas fa-external-link-alt</v-icon>
              {{ item.data }}
              &nbsp;<br/>
              <v-icon size="10" class="ml-2" >
                {{ item.lido ? "fas fa-check" : "fas fa-circle red--text" }}</v-icon
              >
            </v-chip>
            <div class="clearBoth" />
          </v-card-text>
        </v-card>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  methods: {
    ...mapMutations(["showmsg", "updateBadges"]),
    lerItem(item) {
      if (item.lido) {
        if (item.link) this.$util.link(item.link, {});
      } else {
        item.lido = true;
        if (this.badges.avisos)
          this.updateBadges({ avisos: this.badges.avisos - 1 });
      }
    }
  },
  computed: {
    ...mapState(["badges"])
  },
  data: () => ({
    items: []
  })
};
</script>
